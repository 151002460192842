// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-am-js": () => import("./../../../src/pages/case-study-am.js" /* webpackChunkName: "component---src-pages-case-study-am-js" */),
  "component---src-pages-case-study-automation-js": () => import("./../../../src/pages/case-study-automation.js" /* webpackChunkName: "component---src-pages-case-study-automation-js" */),
  "component---src-pages-case-study-cioffilawfirm-js": () => import("./../../../src/pages/case-study-cioffilawfirm.js" /* webpackChunkName: "component---src-pages-case-study-cioffilawfirm-js" */),
  "component---src-pages-case-study-metavaders-js": () => import("./../../../src/pages/case-study-metavaders.js" /* webpackChunkName: "component---src-pages-case-study-metavaders-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

